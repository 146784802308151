@theme: #ed6d00;
@space: 15px;
@background: #f7f7f7;
@font-default: 14px;
@font-header: 16px;
@font-larger: 32px;
@font-large: 18px;

body {
  background-color: @background;
  user-select: none;
  font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  font-size: @font-default;
  margin: 0;
  color: #222;

  // &.loading {
  //   overflow: hidden;
  // }

  // &.loading:after, &.loading:before {
  //   content: '';
  //   position: fixed;
  //   z-index: 1000;
  //   top: 0;
  //   bottom: 0;
  //   right: 0;
  //   left: 0;
  //   background-color: #fff;
  // }

  &.loading:after {
    background: #fff url("../assets/logo.png") no-repeat center;
    background-size: 100px;
    animation: fade 1.5s infinite alternate;
    z-index: 1001;
  }
}

@keyframes fade {
  0% {
    opacity: .3;
  }

  100% {
    opacity: 1;
  }
}

.clean-padding {
  box-sizing: border-box;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}